import { SEO } from "../../components/SEO";
import { getPublicAssetPath } from "../../utils/getPublicAssets";
import "./Convergence.css";

export const Convergence = () => {
  return (
    <div className="convergence-main-section">
      <SEO
        title="Heureka Quest | Convergence"
        description="Welcome to the Convergence page of Heureka Quest"
      />
      {/* Top Para Section */}
      <div className="top-para-container">
        <p>
          The food system is a complex web of activities involving the
          production, processing, transport, and consumption. Issues concerning
          the food system include the governance and economics of food
          production, its sustainability, the degree to which we waste food, how
          food production affects the natural environment and the impact of food
          on individual and population health.
        </p>
      </div>

      {/* Main Image Section */}
      <div className="main-img-section">
        <img
          className="main-img"
          src={getPublicAssetPath("convergence/conv-main.svg")}
          alt="main-img"
        />
        <img
          className="stake-8-img"
          src={getPublicAssetPath("convergence/conv-stk-8.svg")}
          alt="stake-8"
        />
        <img
          className="stake-9-img"
          src={getPublicAssetPath("convergence/conv-stk-9.svg")}
          alt="stake-9"
        />
      </div>
    </div>
  );
};
