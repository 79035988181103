import { SEO } from "../../components/SEO";
import "./PrivacyPolicy.css";

export const PrivacyPolicy = () => {
  return (
    <div className="pp-main-section">
      <SEO
        title="Heureka Quest | Privacy Policy"
        description="Welcome to the Privacy Policy page of Heureka Quest"
      />
      <div className="pp-detail-info">
        <h1>Privacy Policy</h1>
        <h3>Inroduction</h3>
        <p>
          Heureka Quest is an open-source citizen science platform run by the
          Bharti Institute of Public Policy, Indian School of Business,
          Hyderabad, India. In this Privacy Policy (“Policy”), we describe what
          information we collect from you as a user of our Site or Service, why
          we collect and use it, and how you can manage and delete your data. At
          Heureka Quest, we recognize that your personal information is an
          integral part of your identity and deserves the utmost respect and
          protection. We are dedicated to empowering you to contribute to a
          sustainable future through citizen science while upholding the highest
          standards of privacy and data security. This comprehensive Privacy
          Policy is designed to provide you with a clear and transparent
          understanding of how we collect, use, disclose, and safeguard your
          information. By engaging with our platform, you signify your
          acceptance of the terms outlined in this policy.{" "}
        </p>
        <h3>Definitions</h3>
        <p>
          “Personal Information” refers to any data that can directly or
          indirectly identify you, or could potentially be used to do so. This
          includes details like your name, email address, and interactions on
          our platform. We interpret this broadly, encompassing definitions in
          regulations like the Digital Personal Data Protection Act (DPDP Act)
          of India, but excluding truly anonymized data and professional contact
          info used solely for business purposes. "Site" covers all web pages
          within "heureka.quest" domain and subdomains, plus any hosted on our
          servers. "Service" refers to both website functionality and any mobile
          apps we may provide.{" "}
        </p>
        <h3>Your Privacy is Paramount</h3>
        <p>
          We believe that informed consent is the cornerstone of a trustworthy
          relationship. We are committed to ensuring that you have full control
          over your personal information and that you make conscious choices
          about how it is used. We will never collect, use, or share your
          information without your explicit permission, except where required by
          law or to protect the safety and integrity of our platform.
        </p>
        <h3>Information We Collect</h3>
        <p>We collect information from you in two primary ways :</p>
        <p>
          Information You Provide Directly :<br />
          Account Creation : When you create an account, we ask for essential
          information such as your name, email address, and a secure password.
          You may choose to provide additional information for your profile,
          such as your location, interests, and a profile picture.{" "}
        </p>
        <p>
          Research Participation : <br />
          If you choose to participate in citizen science projects, we may
          collect data related to your research activities. This could include
          observations, measurements, photographs, videos, audio recordings, and
          other data you contribute. You will always be informed about the
          specific types of data being collected and how they will be used
          before you participate in any project.
        </p>
        <p>
          Communications and Interactions : <br />
          When you communicate with us through email, support requests, or
          feedback forms, we collect the information you provide. We also
          collect information about your interactions with our platform, such as
          the pages you visit, the features you use, and the time you spend on
          our site.
        </p>
        <p>
          Payment Information : <br />
          If you make any donations/grant to our platform, we collect payment
          information necessary to process the transaction. We use secure
          third-party payment processors to handle this sensitive data, and we
          do not store your full payment card details on our servers.
        </p>
        <p>
          Information We Collect Automatically : <br />
          Device and Usage Data : We may automatically collect certain
          information about your device and how you interact with our platform.
          This includes your IP address, browser type, operating system, device
          identifiers, and information about your browsing activities. We use
          this data to analyze trends, administer the website, track users'
          movements around the site, and gather demographic information about
          our user base as a whole.
        </p>
        <p>
          Location Data : With your explicit permission, we may collect
          information about your precise or approximate location using
          technologies such as GPS, Wi-Fi, and mobile network data. This helps
          us provide location-based services, such as connecting you with
          relevant projects in your area. You can control the collection of
          location data through your device settings or within our platform.
        </p>
        <p>
          Cookies and Similar Technologies : We use cookies and similar
          technologies (e.g., web beacons, pixels, tags) to enhance your
          experience on our platform. These technologies help us remember your
          preferences, analyze usage patterns, and provide personalized content.
          You can control the use of cookies through your browser settings, but
          disabling cookies may limit your access to certain features or
          functions on our site.  
        </p>
        <h3>How We Use Your Information</h3>
        <p>We use the information we collect for the following purposes :</p>

        <strong>Providing and Enhancing Your Experience : </strong>
        <ul>
          <li>
            To create and manage your account, personalize your experience, and
            provide you with the services and features you request.
          </li>
          <li>
            To communicate with you about your account, project participation,
            and other relevant information.{" "}
          </li>
          <li>
            To analyze usage patterns and improve our platform's functionality,
            performance, and user experience.
          </li>
          <li>
            To develop new features and services based on your feedback and
            needs.
          </li>
        </ul>

        <strong>Supporting Citizen Science and Sustainability : </strong>
        <ul>
          <li>
            To collect, analyze, and interpret research data contributed by
            participants, with the goal of advancing scientific knowledge and
            promoting sustainability.
          </li>
          <li>
            To share research findings with the scientific community,
            policymakers, and the public, while protecting the privacy of
            individual participants.
          </li>
          <li>
            To facilitate collaboration and communication among researchers,
            participants, and other stakeholders working towards a sustainable
            future.
          </li>
        </ul>

        <strong>Communication and Engagement : </strong>
        <ul>
          <li>
            To send you newsletters, updates, and other informational
            communications about our platform, citizen science projects, and
            sustainability initiatives. You can opt out of these communications
            at any time.
          </li>
          <li>
            To notify you about new projects, events, and opportunities that may
            be of interest to you based on your preferences and profile
            information.
          </li>
          <li>
            To solicit your feedback and participation in surveys and research
            studies aimed at improving our platform and understanding the impact
            of citizen science.
          </li>
          <li>
            To respond to your inquiries and provide timely and effective
            customer support.
          </li>
        </ul>

        <strong>Marketing and Fundraising (with your consent) : </strong>
        <ul>
          <li>
            To promote our platform, its mission, and the value of citizen
            science in creating a sustainable future. To inform you about
            fundraising campaigns and opportunities to support our work,
            ensuring that you have full control over whether and how you
            contribute.
          </li>
          <li>
            To send you targeted advertisements or promotional materials based
            on your interests and demographics, only if you have given us your
            explicit consent to do so. You can withdraw your consent at any
            time.
          </li>
        </ul>

        <strong>Legal Compliance and Protection : </strong>
        <ul>
          <li>
            To comply with applicable laws, regulations, and legal processes,
            ensuring that we operate ethically and responsibly.
          </li>
          <li>
            To protect our rights, property, and safety, as well as the rights,
            property, and safety of our users and others. This includes sharing
            information to prevent fraud, security breaches, or other
            potentially harmful activities.
          </li>
        </ul>

        <h3>Recognizing Your Contributions</h3>
        <p>
          We deeply appreciate the invaluable contributions of citizen
          scientists to our platform and the broader scientific community. We
          are committed to acknowledging and attributing your work
          appropriately. When research findings or data derived from your
          contributions are published or shared, we will make every effort to
          recognize your involvement, unless you request otherwise.
        </p>

        <h3>Your Data, Your Choice</h3>
        <p>
          We believe that you should have full ownership and control over the
          data you contribute to our platform. You have the right to access,
          modify, or delete your research data at any time. We will also provide
          you with clear and transparent information about how your data is
          being used and shared, and we will obtain your explicit consent before
          using your data for any purposes beyond those outlined in this Privacy
          Policy.
        </p>

        <h3>How We Share Your Information</h3>
        <p>
          We understand the importance of safeguarding your personal information
          and will never sell or rent your data to third parties. We may share
          your information with certain trusted partners under the following
          circumstances :
        </p>
        <p>
          With Your Explicit Consent : We will only share your information with
          third parties if you have given us your clear and unambiguous consent
          to do so.
        </p>
        <p>
          With Service Providers : We may share your information with carefully
          selected third-party service providers who assist us in operating our
          platform and providing services to you. These providers are
          contractually obligated to handle your information securely and only
          for the purposes specified by us.
        </p>
        <p>
          With Research Partners : We may share anonymized or aggregated
          research data with research partners who are collaborating with us on
          citizen science projects. We will ensure that these partners have
          appropriate data protection measures in place and that they use your
          data solely for research purposes.{" "}
        </p>
        <p>
          For Legal Compliance and Protection : We may share your information if
          required to do so by law or in response to a valid legal request. We
          may also share your information to protect our rights, property, and
          safety, or the rights, property, and safety of our users or others.
        </p>
        <p>
          In Connection with a Business Transaction : In the event of a merger,
          acquisition, or other business transaction, we may transfer your
          information to the acquiring entity or its advisors. We will notify
          you of any such transfer and provide you with the opportunity to opt
          out if the new entity's privacy practices differ materially from ours.
        </p>

        <h3>Your Choices and Rights</h3>
        <p>
          You have the following choices and rights regarding your information :{" "}
        </p>
        <p>
          Access and Control : You can access, review, and update your personal
          information at any time by logging into your account and visiting your
          profile settings.{" "}
        </p>
        <p>
          Opt-Out : You can opt out of receiving marketing communications,
          location tracking, and other data collection practices by adjusting
          your settings within our platform or contacting us directly.{" "}
        </p>
        <p>
          Data Portability : You have the right to request a copy of your
          personal information in a structured, machine-readable format.
        </p>
        <p>
          Detection : You can request to delete your account and associated
          personal information. Please note that some information may be
          retained for legal or archival purposes.
        </p>

        <h3>Data Security</h3>
        <p>
          We implement robust security measures to protect your information from
          unauthorized access, use, disclosure, or destruction. These measures
          include :{" "}
        </p>
        <p>
          Encryption : We use industry-standard encryption technologies to
          protect sensitive information during transmission and storage.{" "}
        </p>
        <p>
          Access Controls : We restrict access to your information to authorized
          personnel who require it to perform their duties.{" "}
        </p>
        <p>
          Third-Party Security : We carefully select and vet third-party service
          providers to ensure they have appropriate security measures in place
          to protect your information
        </p>
        <p>
          Data Retention : We retain your information only for as long as
          necessary to fulfill the purposes outlined in this Privacy Policy or
          as required by law.
        </p>
        <p>
          While we strive to protect your information, no security system is
          completely foolproof. We cannot guarantee the absolute security of
          your information.
        </p>

        <h3>Children's Privacy</h3>
        <p>
          Our platform is not directed to children under the age of 10. We do
          not knowingly collect personal information from children under 10. If
          we become aware that we have collected personal information from a
          child under 10 without parental consent, we will take steps to delete
          such information from our servers.{" "}
        </p>
      </div>
    </div>
  );
};
