import React from "react";
import "./NotFound.css";
import { SEO } from "../../components/SEO";
import { Link } from "react-router-dom";

export const NotFound: React.FC = () => {
  return (
    <>
      <SEO
        title="Heureka Quest | 404"
        description="The page you are looking for on Heureka Quest could not be found. Explore our site for more information and resources."
      />
      <div className="not-found-section">
        <div className="not-found-container">
          <div className="not-found-img-container">
            <img src="/not-found.svg" alt="not-found" />
          </div>
          <div className="not-found-detail-container">
            <h1>404</h1>
            <h2>UH OH! You're lost.</h2>
            <p>
              The page you are looking for does not exist. How you got here is a
              mystery. But you can click the button below to go back to the
              signin page.
            </p>
            <Link to="/signin">
              <button className="home-btn">SignIn</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
