import { request } from "./ApiCentral";


export const getProfile = (data) =>{
  console.log(data, " get user profile")
  return request(
      '/profile/get-profile',
      {
        method: 'POST',
        data,
      },
      false,
      data.token,
      false,
    );
}
