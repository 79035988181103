import { SignIn } from "../pages/SignIn/SignIn";
import { Home } from "../pages/Home/Home";
import { NotFound } from "../pages/NotFound/NotFound";
import { Landing } from "../pages/Landing/Landing";
import { About } from "../pages/About/About";
import { FoodSystem } from "../pages/FoodSystem/FoodSystem";
import { Convergence } from "../pages/Convergence/Convergence";
import { ContactUs } from "../pages/ContactUs/ContactUs";
import { PrivacyPolicy } from "../pages/PrivacyPolicy/PrivacyPolicy";
import { TermsCondition } from "../pages/T&C/TermsCondition";

interface Route {
    path: string;
    component: React.FC;
    isProtected?: boolean;
}

const routes: Route[] = [
    // {
    //     path: '/',
    //     component: Landing,
    // },
    {
        path: '/signin',
        component: SignIn,
    },
    {
        path: '/home',
        component: Home,
        isProtected: true,
    },
    // {
    //     path: '/about',
    //     component: About,
    // },
    {
        path: '/food-system',
        component: FoodSystem,
    },
    {
        path: '/convergence',
        component: Convergence,
    },
    // {
    //     path: '/contact',
    //     component: ContactUs,
    // },
    {
        path: '/privacy-policy',
        component: PrivacyPolicy,
    },
    {
        path: '/terms-and-condition',
        component: TermsCondition,
    },
    {
        path: '*', // Catch-all route for 404
        component: NotFound,
    },
];

export default routes;
