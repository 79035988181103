import { getProfile,} from "../services/ProfileServices";



  export const handleGetProfile = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return getProfile(data)
      .then(async ({ data: response }) => {
        console.log("RES", response);
        callback(response);
        dispatch({ type: "UPDATE_USER_INFO", payload: response });
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        callback(err?.response?.data);
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        dispatch({ type: "SHOW_ERROR", payload: err });
      });
  };

  