import { combineReducers } from 'redux';
import userInfo from './entities/AuthReducer';
import appInfo from './entities/AppUtils';

const reducers = combineReducers({
  userInfo: userInfo,
  appInfo: appInfo
});

export default reducers;
