import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { useLocation } from "react-router-dom";

const normalizePath = (path: string) => path.replace(/\/+$/, "");

const App: React.FC = () => {
  const location = useLocation();
  const isSrollableRoute = ["", "/about", "/food-system"].includes(
    normalizePath(location.pathname)
  );
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      mirror: true,
      easing: "ease-in-out",
    });
    return () => {
      AOS.refresh();
    };
  }, []);

  return (
    <>
      {isSrollableRoute ? (
        <div className="scrollable-layout">
          <Header />
          <main>
            <AppRoutes />
          </main>
          <Footer />
        </div>
      ) : (
        <div className="fullscreen-layout">
          <Header />
          <main>
            <AppRoutes />
          </main>
          <Footer />
        </div>
      )}
    </>
  );
};

export default App;
