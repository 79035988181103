import React from "react";
import "./Footer.css";
import { getPublicAssetPath } from "../../utils/getPublicAssets";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="link-container">
        <ul>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms-and-condition">Terms and Conditions</Link>
          </li>
          {/* <li>
            <a href="#section3">Support and Team</a>
          </li> */}
        </ul>
      </div>
      <div className="icons-container">
        <img
          src={getPublicAssetPath("footer/youtube-icon.svg")}
          alt="youtube-icon"
        />
        <img
          src={getPublicAssetPath("footer/insta-icon.svg")}
          alt="insta-icon"
        />
        <img src={getPublicAssetPath("footer/x-icon.svg")} alt="x-icon" />
        <img
          src={getPublicAssetPath("footer/github-icon.svg")}
          alt="github-icon"
        />
        <img
          src={getPublicAssetPath("footer/linkedin-icon.svg")}
          alt="linkedin-icon"
        />
      </div>
    </footer>
  );
};

export default Footer;
